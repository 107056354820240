import { isInteger, isString } from 'lodash';

import i18n from 'utils/i18n';
import { isValidGtin } from 'utils/validator/gtin';

const PRODUCT_IDENTIFIER_MAX_LENGTH = 128;

export const isValidGln = (gln, paddable = false) => {
  // TODO do something generic for GTINs and GLNs using check digits
  if (paddable ? gln.length > 13 : gln.length !== 13) {
    return false;
  }
  let i = 0;
  while (i < gln.length) {
    if (gln[i] < '0' || gln[i] > '9') {
      return false;
    }
    i += 1;
  }
  return true;
};

// looks like it works quite fine : http://emailregex.com/
// in short: name does not start with a dot and does not contain one of []()\,;: @, except if between ""
// then domain is either an IP, or a name followed by a dot, and then at least 2 alphabetical characters
export const EMAIL_REG_EXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const EMAIL_LIST_REG_EXP =
  /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/gm;

export function isValidEmail(email) {
  if (typeof email === 'string') {
    return EMAIL_REG_EXP.test(email.trim());
  }
  return false;
}

export const isValidProductIdentifier = (productIdentifier) => {
  if (!isString(productIdentifier) && !isInteger(productIdentifier)) {
    return {
      isValid: false,
      errorMessage: i18n.t(
        'frontproductstream.validator_utils.product_identifier.alphanumeric_error',
        { defaultValue: 'An identifier should be alphanumeric characters' },
      ),
    };
  } else if (productIdentifier.length > PRODUCT_IDENTIFIER_MAX_LENGTH) {
    // check the max length
    return {
      isValid: false,
      errorMessage: i18n.t(
        'frontproductstream.validation_utils.product_identifier.max_length_error',
        {
          defaultValue: `Product identifier cannot be more than ${PRODUCT_IDENTIFIER_MAX_LENGTH} characters`,
        },
      ),
    };
  } else if (!/^[A-Za-z0-9-_]+$/.test(productIdentifier)) {
    // check allowed characters
    return {
      isValid: false,
      errorMessage: i18n.t(
        'frontproductstream.validation_utils.product_identifier.allowed_characters_error',
        {
          defaultValue:
            'Only alphanumeric (A-Z, a-z, 0-9), dash (-) and underscore (_) characters are permitted in a product identifier',
        },
      ),
    };
  } else if (
    [12, 13, 14].includes(productIdentifier.length) &&
    isValidGtin(productIdentifier)
  ) {
    // check if valid gtin only if length after blank space strip equals 12, 13 or 14
    return {
      isValid: false,
      errorMessage: i18n.t(
        'frontproductstream.validation_utils.product_identifier.not_gtin_error',
        { defaultValue: 'Product identifier cannot be a valid GTIN' },
      ),
    };
  }
  return { isValid: true, errorMessage: undefined };
};
