import { call, put, select } from 'redux-saga/effects';

import { FEATURE_PRODUCT_REVIEW } from 'modules/feature-flag';
import { selectHasFeature } from 'modules/feature-flag/selectors';
import { selectRecipients } from 'modules/recipients';
import { selectIsManufacturer, selectIsRetailer } from 'modules/user';
import { selectProductKeyId, selectShares } from 'reducers/productVersion';
import { getPendingReviewFields } from 'resources/productReviewApi';
import { Recipient } from 'types';
import { get } from 'utils/immutable';

import { receiveRejectedFields } from '../actions';

export function* fetchRejectedFieldsSaga() {
  const isManufacturer = yield select(selectIsManufacturer);
  const isRetailer = yield select(selectIsRetailer);
  let organizationByProductKeyIds = {};
  if (isManufacturer) {
    const recipientsWithReviewEngine: Recipient[] = (yield select(
      selectRecipients,
    ))
      .filter((recipient) =>
        get(recipient, ['settings', FEATURE_PRODUCT_REVIEW]),
      )
      .map((recipient) => recipient.get('id'));
    organizationByProductKeyIds = (yield select(selectShares))
      .toJS()
      .filter(({ targetOrganization }) =>
        recipientsWithReviewEngine.includes(targetOrganization.id),
      )
      .reduce((acc, { targetOrganization, targetProductKey }) => {
        acc[targetProductKey.id] = targetOrganization;
        return acc;
      }, {});
  } else if (isRetailer) {
    const reviewSetting = yield select(
      selectHasFeature(FEATURE_PRODUCT_REVIEW),
    );
    if (!reviewSetting) {
      yield put(receiveRejectedFields({}));
      return {};
    }
    const productKeyId = yield select(selectProductKeyId);
    organizationByProductKeyIds = {
      [productKeyId]: null,
    };
  }
  if (Object.keys(organizationByProductKeyIds).length > 0) {
    const { data: summary } = yield call(
      getPendingReviewFields,
      Object.keys(organizationByProductKeyIds).map(Number),
    );
    const rejectedFields = Object.entries(summary).reduce(
      (acc, [productKeyId, fields]) => {
        for (const field of fields as string[]) {
          acc[field] = [
            ...(acc[field] ?? []),
            organizationByProductKeyIds[productKeyId],
          ];
        }
        return acc;
      },
      {},
    );
    yield put(receiveRejectedFields(rejectedFields));
    return rejectedFields;
  } else {
    yield put(receiveRejectedFields({}));
    return {};
  }
}
