import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './index.scss';

export default class Pagination extends PureComponent {
  static propTypes = {
    page: PropTypes.number,
    itemsPerPage: PropTypes.number,
    total: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    nextPageDisabled: PropTypes.bool,
  };

  static defaultProps = {
    page: 1,
    itemsPerPage: 8,
    total: 0,
    nextPageDisabled: false,
  };

  onPreviousPage = () => {
    if (!this.isPreviousDisabled()) {
      this.props.onChange(this.props.page - 1);
    }
  };

  onNextPage = () => {
    if (!this.isNextDisabled()) {
      this.props.onChange(this.props.page + 1);
    }
  };

  getTotalPages() {
    return Math.ceil(this.props.total / this.props.itemsPerPage) || 1;
  }

  isPreviousDisabled() {
    return !!(this.props.page <= 1);
  }

  isNextDisabled() {
    return (
      this.props.nextPageDisabled || this.props.page >= this.getTotalPages()
    );
  }

  render() {
    return (
      <div className="Pagination">
        <i
          data-testid="pagination-previous"
          className="mdi mdi-24px mdi-arrow-left-bold"
          disabled={this.isPreviousDisabled()}
          onClick={this.onPreviousPage}
        />
        <span className="Pagination__pages">
          {`${this.props.page} / ${this.getTotalPages()}`}
        </span>
        <i
          data-testid="pagination-next"
          className="mdi mdi-24px mdi-arrow-right-bold"
          disabled={this.isNextDisabled()}
          onClick={this.onNextPage}
        />
      </div>
    );
  }
}
