import { useNavigate } from 'react-router-dom';

import EmptyState from '@alkem/react-ui-empty-state';

import { openHelpCenter } from 'modules/help';
import * as routes from 'routes';
import i18n from 'utils/i18n';

export default function CatalogEmptyAssignationTable() {
  const navigate = useNavigate();
  let title;
  let text;
  let action;
  let tips;
  let tipsAction;
  title = i18n.t('No request found');
  text = i18n.t(
    "Looks like you don't have pending requests from retailers. Get started by adding products to your catalog.",
  );
  action = {
    label: i18n.t('Go to catalog'),
    onClick: () => navigate(routes.catalogAll),
  };
  tips = (
    <span>
      {i18n.t(
        "If you have received emails with requests from retailers that don't appear here, you can contact us",
      )}
    </span>
  );
  tipsAction = {
    label: i18n.t('Contact support'),
    onClick: () => openHelpCenter({ module: 20 }),
  };
  return (
    <EmptyState
      title={title}
      text={text}
      action={action}
      tips={tips}
      tipsAction={tipsAction}
    />
  );
}
