import { fromJS } from 'immutable';
import { once } from 'lodash';
import { compose } from 'redux';
import { createReducer } from 'redux-create-reducer';

import {
  initSelectedFilterState,
  updateSelectedFilters,
  updateSelectedFiltersFromQuery,
} from 'core/modules/list/reducers/filters';
import {
  collapseFilters,
  initFiltersConfigState,
  updateDuplicatedProducts,
  updateFilteredFilters,
  updateFiltersPagination,
  updateWithAllTypes,
  updateWithArchivedProducts,
  updateWithRefusedAssignations,
} from 'core/modules/list/reducers/filters-config';
import {
  createSelectEntitiesReducer,
  sortFilterByDocCountReducer,
} from 'core/modules/list/reducers/list';
import {
  initPaginationState,
  updateItemsPerPage,
} from 'core/modules/list/reducers/pagination';
import { SAVE_USER_LABELS_DONE } from 'modules/user-label';
import storage from 'utils/storage';

import {
  CANCEL_FETCH_COLUMN_REFERENTIALS,
  CANCEL_FETCH_LIST,
  CLOSE_EXPORT_DESIRED_PRODUCTS_MODAL,
  CLOSE_EXPORT_MODAL,
  COLLAPSE_FILTERS,
  DISPATCH_PRODUCTS,
  EXPORT_DESIRED_PRODUCTS,
  EXPORT_DESIRED_PRODUCTS_DONE,
  EXPORT_PRODUCTS,
  EXPORT_PRODUCTS_DONE,
  FETCH_COLUMN_REFERENTIALS,
  FETCH_LIST,
  OPEN_EXPORT_DESIRED_PRODUCTS_MODAL,
  OPEN_EXPORT_MODAL,
  PRODUCT_SELECTION,
  RECEIVE_COLUMN_REFERENTIALS,
  RECEIVE_EXPORT_FILE_FORMATS,
  RECEIVE_LIST,
  RECEIVE_MATURITY_RULESETS,
  RECEIVE_PUBLIC_PRODUCT,
  REPLACE_FILTERS,
  RESET_CATALOG,
  SEARCH,
  SET_CATALOG_CONTEXT,
  SET_CATALOG_UUID,
  SET_FILTERS_FROM_REPORTING,
  SET_HIGHLIGHTED_ROW_IDENTIFIER,
  SORT_FILTER_BY_DOC_COUNT,
  SORT_LIST,
  UPDATE_ASSIGNATION_STATUS,
  UPDATE_COLUMN_SELECTION,
  UPDATE_EXPORT_FORMAT,
  UPDATE_FILTERED_FILTERS,
  UPDATE_FILTERS_PAGINATION,
  UPDATE_ITEMS_PER_PAGE,
  UPDATE_ONLY_DUPLICATED_PRODUCTS,
  UPDATE_PRODUCTS_TO_EXPORT,
  UPDATE_SEARCH_FROM_QUERY,
  UPDATE_SELECTED_FILTERS,
  UPDATE_SELECTED_FILTERS_FROM_QUERY,
  UPDATE_WITH_ALL_TYPES,
  UPDATE_WITH_ARCHIVED_PRODUCTS,
  UPDATE_WITH_REFUSED_ASSIGNATIONS,
} from '../actions/types';
import { PRODUCTS } from '../constants/context';
import { FILTERS_CONFIG_KEY, ITEMS_PER_PAGE_KEY } from '../constants/storage';

import { updateAssignationStatus } from './assignation';
import { updateColumnSelection } from './column';
import {
  cancelFetchColumnReferentials,
  fetchColumnReferentials,
  receiveColumnReferentials,
} from './column-referentials';
import {
  cancelFetchList,
  fetchList,
  receiveList,
  search,
  sortList,
  updateUserLabels,
} from './list';
import { receiveMaturityRulesets } from './maturity-rulesets';

export const initialState = fromJS({
  uuid: '',
  currentPublicProduct: null,
  productList: [],
  productMap: {},
  selectedMap: {},
  columnMap: {},
  referentials: [],
  queries: [],
  search: '',
  aggregations: {},
  isFetchingList: false,
  isFetchingReferentials: false,
  exportInProgress: false,
  isExportModalOpen: false,
  reportingFilters: null,
  exportFormat: null,
  exportFileFormats: null,
  maturityRulesets: [],
  highlightedRowIdentifier: null,
});

// TODO 2018-07-17: remove in few weeks
const migrateStorageConfig = once(() => {
  const filterConfig = storage.getItem(FILTERS_CONFIG_KEY);
  if (filterConfig) {
    storage.setItem(`${FILTERS_CONFIG_KEY}/${PRODUCTS.type}`, filterConfig);
    storage.removeItem(FILTERS_CONFIG_KEY);
  }
  const itemsPerPage = storage.getItem(ITEMS_PER_PAGE_KEY);
  if (itemsPerPage) {
    storage.setItem(`${ITEMS_PER_PAGE_KEY}/${PRODUCTS.type}`, itemsPerPage);
    storage.removeItem(ITEMS_PER_PAGE_KEY);
  }
});

const enhanceInitialState = (context = {}) => {
  migrateStorageConfig();
  return compose(
    initFiltersConfigState(`${FILTERS_CONFIG_KEY}/${context.type}`),
    initPaginationState(`${ITEMS_PER_PAGE_KEY}/${context.type}`),
    initSelectedFilterState,
  );
};

export default createReducer(initialState, {
  [FETCH_LIST]: fetchList,
  [CANCEL_FETCH_LIST]: cancelFetchList,
  [RECEIVE_LIST]: receiveList,
  [FETCH_COLUMN_REFERENTIALS]: fetchColumnReferentials,
  [CANCEL_FETCH_COLUMN_REFERENTIALS]: cancelFetchColumnReferentials,
  [RECEIVE_COLUMN_REFERENTIALS]: receiveColumnReferentials,
  [RECEIVE_MATURITY_RULESETS]: receiveMaturityRulesets,
  [PRODUCT_SELECTION]: createSelectEntitiesReducer('productList'),
  [UPDATE_COLUMN_SELECTION]: updateColumnSelection,
  [SORT_FILTER_BY_DOC_COUNT]: sortFilterByDocCountReducer,
  [SORT_LIST]: sortList,
  [SEARCH]: search,
  [UPDATE_SEARCH_FROM_QUERY]: search,
  [UPDATE_SELECTED_FILTERS]: updateSelectedFilters,
  [UPDATE_SELECTED_FILTERS_FROM_QUERY]: updateSelectedFiltersFromQuery,
  [EXPORT_PRODUCTS]: (state) => state.set('exportInProgress', true),
  [EXPORT_PRODUCTS_DONE]: (state) => state.set('exportInProgress', false),
  [EXPORT_DESIRED_PRODUCTS]: (state) =>
    state.set('exportDesiredProductsInProgress', true),
  [EXPORT_DESIRED_PRODUCTS_DONE]: (state) =>
    state.set('exportDesiredProductsInProgress', false),
  [COLLAPSE_FILTERS]: collapseFilters,
  [UPDATE_FILTERED_FILTERS]: updateFilteredFilters,
  [UPDATE_FILTERS_PAGINATION]: updateFiltersPagination,
  [UPDATE_ITEMS_PER_PAGE]: updateItemsPerPage,
  [OPEN_EXPORT_MODAL]: (state) => state.set('isExportModalOpen', true),
  [OPEN_EXPORT_DESIRED_PRODUCTS_MODAL]: (state) =>
    state.set('isExportDesiredProductsModalOpen', true),
  [CLOSE_EXPORT_MODAL]: (state) => state.set('isExportModalOpen', false),
  [CLOSE_EXPORT_DESIRED_PRODUCTS_MODAL]: (state) =>
    state.set('isExportDesiredProductsModalOpen', false),
  [UPDATE_EXPORT_FORMAT]: (state, { payload } = {}) =>
    state.set('exportFormat', payload),
  [UPDATE_WITH_ARCHIVED_PRODUCTS]: updateWithArchivedProducts,
  [UPDATE_ONLY_DUPLICATED_PRODUCTS]: updateDuplicatedProducts,
  [UPDATE_WITH_ALL_TYPES]: updateWithAllTypes,
  [UPDATE_WITH_REFUSED_ASSIGNATIONS]: updateWithRefusedAssignations,
  [UPDATE_ASSIGNATION_STATUS]: updateAssignationStatus,
  [RESET_CATALOG]: (state) =>
    state.merge(
      initialState.filterNot((value, key) =>
        ['queries', 'search', 'reportingFilters'].includes(key),
      ),
    ),
  [REPLACE_FILTERS]: (state, { payload: { filterList, filterMap } }) =>
    state
      .set('selectedFilterList', filterList)
      .set('selectedFilterMap', filterMap),
  [SET_CATALOG_CONTEXT]: (state, { payload: { context } }) =>
    enhanceInitialState(context)(initialState)
      .set('context', context)
      .set('search', state.get('search'))
      .set('reportingFilters', state.get('reportingFilters')),
  [RECEIVE_PUBLIC_PRODUCT]: (state, { payload }) =>
    state.set('currentPublicProduct', payload),
  [SAVE_USER_LABELS_DONE]: updateUserLabels,
  [SET_FILTERS_FROM_REPORTING]: (state, action) =>
    state.set('reportingFilters', action.payload),
  [SET_CATALOG_UUID]: (state, action) => state.set('uuid', action.payload),
  [RECEIVE_EXPORT_FILE_FORMATS]: (state, { payload }) =>
    state.set('exportFileFormats', payload),
  [UPDATE_PRODUCTS_TO_EXPORT]: (state, { payload }) =>
    state.set('productsToExport', payload),
  [DISPATCH_PRODUCTS]: (state, { payload }) =>
    state.set('exportProducts', fromJS(payload)),
  [SET_HIGHLIGHTED_ROW_IDENTIFIER]: (state, { payload }) =>
    state.set('highlightedRowIdentifier', payload),
});
