import classNames from 'classnames';
import { get } from 'lodash';
import { connect } from 'react-redux';

import { Text as ComponentText } from '@alkem/react-ui-inputs';

import Field from 'components/ui/form/field';
import { isValidProductIdentifier } from 'utils/validation';

export class FormProductIdentifier extends Field {
  static propTypes = Object.assign({}, Field.propTypes);

  static defaultProps = Object.assign({}, Field.defaultProps);

  constructor(props) {
    super(props);
    this.state.errorMessage = '';
  }

  componentDidMount() {
    const { value } = this.props;
    this.validate(value);
  }

  componentDidUpdate() {
    this.ensureDataIsPresent();
  }

  clearErrorMessage = () => {
    const { errorMessage } = this.state;
    if (errorMessage) {
      this.setState({ errorMessage: '' });
    }
  };

  validate = (value) => {
    if (!value) {
      this.clearErrorMessage();
      return;
    }
    const { isValid, errorMessage } = isValidProductIdentifier(value);
    if (isValid) {
      this.clearErrorMessage();
    } else {
      this.setState({ errorMessage });
    }
  };

  handleChange = (event) => {
    this.validate(get(event, 'target.value'));
    super.handleChange(event);
  };

  render() {
    const { field, value } = this.props;
    const { errorMessage } = this.state;

    if (!field) {
      return null;
    }

    const displayPlaceholder = this.shouldDisplayItem('placeholder');
    const content = (
      <div className="InputProductIdentifier">
        <ComponentText
          id={this.getId()}
          onChange={this.handleChange}
          placeholder={displayPlaceholder ? field.placeholder : null}
          value={value || ''}
          disabled={this.isReadOnly()}
        />
      </div>
    );

    const renderedLabel = this.renderLabel('col-xs-4');
    const classes = {
      FormText__input: true,
      FormProductIdentifier__input: true,
      'col-xs-8': renderedLabel,
      'col-xs-12': !renderedLabel,
    };

    return (
      <div
        className={classNames(
          this.getClasses({ 'FormProductIdentifier row': true }),
        )}
      >
        {renderedLabel}
        <div className={classNames(classes)}>
          {content}
          {this.renderPlugins()}
          {errorMessage && (
            <div className="FormField--error">
              <span>{errorMessage}</span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export const ConnectedFormProductIdentifier = connect()(FormProductIdentifier);
export default ConnectedFormProductIdentifier;
