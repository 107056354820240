import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { assignationStatusFilter } from 'core/modules/list/constants/filters';
import { SEARCH as SEARCH_FROM_CATALOG } from 'modules/catalog/product/actions/types';
import { buildSearch } from 'modules/catalog/shared/utils/list';
import { hasFeatureProductReview } from 'modules/product-review/utils';
import { selectIsRetailer, selectUser } from 'modules/user';
import { fetchAssignations, fetchProductVersions } from 'resources/searchApi';
import { get } from 'utils/immutable';
import { withCatch } from 'utils/saga';

import { receiveAggregations } from '../actions';
import { FETCH_AGGREGATIONS } from '../actions/types';
import { selectSearch, selectUseCatalogWithMenu } from '../selectors';

export default function* catalogMenuSaga() {
  yield takeLatest(
    [FETCH_AGGREGATIONS, SEARCH_FROM_CATALOG],
    withCatch(fetchAggregationsSaga),
  );
}

export function* fetchAggregationsSaga() {
  const hasMenu = yield select(selectUseCatalogWithMenu);
  if (!hasMenu) {
    return;
  }
  const user = yield select(selectUser);
  const queryName = yield select(selectSearch);
  const search = buildSearch(queryName);
  const isRetailer = yield select(selectIsRetailer);
  const [assignationResponse, reviewResponse] = yield all([
    !isRetailer &&
      call(fetchAssignations, {
        limit: 0,
        queries: {
          ...search,
          advancedSearch: {
            must_not: [
              {
                query: 2,
                fields: [assignationStatusFilter.key],
              },
            ],
          },
        },
      }),
    hasFeatureProductReview(user) &&
      call(fetchProductVersions, {
        queries: {
          isReviewPending: true,
        },
        withSourceInclude: false,
        limit: 0,
      }),
  ]);
  yield put(
    receiveAggregations({
      assignations: get(assignationResponse, 'total'),
      review: get(reviewResponse, ['total']),
    }),
  );
}
